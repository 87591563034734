.contactPageContainer {
  z-index: 100;
  margin-top: 15vh;
  width: calc(100% - 10vw);
  padding-left: 5vw;
  padding-right: 5vw;
  height: auto;
  background-color: #fafafa;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .contactPageContainer {
    z-index: 100;
    margin-top: 10vh;
    width: calc(100% - 10vw);
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .contactPageContainer {
    margin-top: 5vh;
    width: calc(100% - 10vw);
    padding-left: 5vw;
    padding-right: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}