.documentacionHeader {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 23vh;
}

.documentacionHeaderText {
  font-family: 'DIN';
  font-weight: 700;
  font-size: 20vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -2vw;
}

.documentacionSubheader {
  width: 100%;
  margin-top: -13vh;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  overflow-x: hidden;
}

.documentacionSubheaderAuxText {
  font-family: 'Helvetica Neue';
  font-weight: 200;
  font-size: 1vw;
  line-height: 2vw;
  color: #2B577C;
  margin-top: 1.5vh;
  margin-bottom: 0;
  width: 20vw;
  text-align: right;
  margin-right: 2vw;
}

.documentacionSubheaderText {
  font-family: 'DIN';
  font-weight: 700;
  font-size: 12vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: -0.5vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .documentacionHeader {
    width: 100%;
    margin-top: 10vh;
  }
  
  .documentacionHeaderText {
    margin-left: -2vw;
  }
  
  .documentacionSubheader {
    width: 100%;
    margin-top: -5vh;
  }
  
  .documentacionSubheaderAuxText {
    font-size: 1.8vw;
    line-height: 3.6vw;
    margin-top: 0.5vh;
    width: 40vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .documentacionHeader {
    width: 100%;
    margin-top: 8vh;
  }
  
  .documentacionHeaderText {
    margin-left: -2vw;
  }
  
  .documentacionSubheader {
    width: 100%;
    margin-top: -4vh;
  }
  
  .documentacionSubheaderAuxText {
    display: none;
  }
}