.factibilidadHeader {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20vh;
}

.factibilidadHeaderText {
  font-family: 'DIN';
  font-weight: 700;
  font-size: 25vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -2vw;
}

.factibilidadSubheader {
  width: 100%;
  margin-top: -16vh;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  overflow-x: hidden;
}

.factibilidadSubheaderAuxText {
  font-family: 'Helvetica Neue';
  font-weight: 200;
  font-size: 1vw;
  line-height: 2vw;
  color: #2B577C;
  margin-top: 1.5vh;
  margin-bottom: 0;
  width: 16vw;
  text-align: right;
  margin-right: 2vw;
}

.factibilidadSubheaderText {
  font-family: 'DIN';
  font-weight: 700;
  font-size: 12vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: -0.5vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .factibilidadHeader {
    width: 100%;
    margin-top: 12vh;
  }
  
  .factibilidadHeaderText {
    margin-left: -2vw;
  }
  
  .factibilidadSubheader {
    width: 100%;
    margin-top: -5vh;
  }
  
  .factibilidadSubheaderAuxText {
    font-size: 1.8vw;
    line-height: 3.6vw;
    margin-top: 0.5vh;
    width: 40vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .factibilidadHeader {
    width: 100%;
    margin-top: 9vh;
  }
  
  .factibilidadHeaderText {
    margin-left: -2vw;
  }
  
  .factibilidadSubheader {
    width: 100%;
    margin-top: -4vh;
  }
  
  .factibilidadSubheaderAuxText {
    display: none;
  }
}