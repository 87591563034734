@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 200;
  src: url('./assets/fonts/HelveticaNeue-Light-08.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  src: url('./assets/fonts/HelveticaNeue-Bold-02.ttf') format('truetype');
}

@font-face {
  font-family: 'DIN';
  font-weight: 700;
  src: url('./assets/fonts/DIN_Condensed_Bold.ttf') format('truetype');
}

body, html {
  height: 100%;
  cursor: none;
}

body {
  overscroll-behavior-y: none;
}

#innerApp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000000000000000000;
  pointer-events: none;
}

.cursor {
  will-change: left, top;
  border-radius: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 100000000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  transition: width 0.3s ease-out, height 0.3s ease-out, background-color 0.3s ease-out, border-color 0.3s ease-out, filter 0.3s ease-out, opacity 0.3s ease-out;
}

.noCursor {
  width: 0px;
  height: 0px;
  opacity: 0;
}

.regularCursor {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 143, 15, 0);
  border-color: rgb(255, 143, 15, 1);
  filter: none;
  opacity: 1;
}

.linkCursor {
  width: 35px;
  height: 35px;
  background-color: rgba(255, 143, 15, 0);
  border-color: rgba(255, 143, 15, 1);
  filter: none;
  opacity: 1;
}

.plusCursor, .leftCursor, .rightCursor, .fbCursor, .igCursor, .mailCursor, .sendCursor, .menuCursor, .xCursor {
  width: 50px;
  height: 50px;
  background-color: rgba(255, 143, 15, 1);
  border: rgba(255, 143, 15, 1);
  filter: none;
  opacity: 1;
}

.plusCursorIcon {
  height: 15px;
  width: 15px;
  color: #fff;
  transition: opacity 0.3s ease-out;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .cursor, .plusCursorIcon {
    display: none;
  }
}