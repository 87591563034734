.computoSubheader {
  width: 100%;
  margin-top: 20vh;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  overflow-x: hidden;
}

.computoSubheaderAuxText {
  font-family: 'Helvetica Neue';
  font-weight: 200;
  font-size: 1vw;
  line-height: 2vw;
  color: #2B577C;
  margin-top: 1.5vh;
  margin-bottom: 0;
  width: 20vw;
  text-align: right;
  margin-right: 2vw;
}

.computoSubheaderText {
  font-family: 'DIN';
  font-weight: 700;
  font-size: 12vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: -0.5vw;
}

.computoHeader {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -8vh;
}

.computoHeaderText {
  font-family: 'DIN';
  font-weight: 700;
  font-size: 24vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -2vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .computoSubheader {
    width: 100%;
    margin-top: 12vh;
  }
  
  .computoSubheaderAuxText {
    font-size: 1.5vw;
    line-height: 3vw;
    margin-top: 0.5vh;
    width: 40vw;
  }
  
  .computoHeader {
    margin-top: -2.5vh;
  }
  
  .computoHeaderText {
    margin-left: -2.5vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .computoSubheader {
    width: 100%;
    margin-top: 12vh;
  }
  
  .computoSubheaderAuxText {
    display: none;
  }
  
  .computoHeader {
    margin-top: -1.5vh;
  }
  
  .computoHeaderText {
    margin-left: -2vw;
  }
}