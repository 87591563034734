.homeFooterContainer {
  width: calc(100% - 10vw);
  padding-right: 5vw;
  padding-left: 5vw;
  padding-bottom: 3vh;
  z-index: 500;
  margin-top: -1px;
}

.homeFooterInnerContainer {
  width: 100%;
  border-top: 1px solid #d1d1d1;
  padding-top: 3vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.homeFooterIcons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.homeFooterButton {
  width: 3vw;
  height: 3vw;
  border-radius: 1.5vw;
  border: 1px solid #d1d1d1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1vw;
  cursor: none;
  transition: opacity 0.3s ease-out;
}

.homeFooterButton:hover {
  opacity: 1;
}

.homeFooterButtonIcon {
  width: 1vw;
  height: 1vw;
  color: #d1d1d1;
}

.homeFooterText {
  font-family: 'Helvetica Neue';
  font-weight: 200;
  font-size: 0.9vw;
  color: #d1d1d1;
  margin-top: 0;
  margin-bottom: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .homeFooterButton {
    width: 5vw;
    height: 5vw;
    border-radius: 2.5vw;
    margin-right: 2vw;
  }
  
  .homeFooterButton:hover {
    opacity: 1;
  }
  
  .homeFooterButtonIcon {
    width: 1.4vw;
    height: 1.4vw;
    color: #d1d1d1;
  }
  
  .homeFooterText {
    font-size: 1.6vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .homeFooterButton {
    width: 10vw;
    height: 10vw;
    border-radius: 5vw;
    margin-right: 3vw;
  }
  
  .homeFooterButton:hover {
    opacity: 1;
  }
  
  .homeFooterButtonIcon {
    width: 3vw;
    height: 3vw;
    color: #d1d1d1;
  }
  
  .homeFooterText {
    font-size: 3vw;
    text-align: right;
  }
}
