.scrollPosition {
  display: none;
}

.inTransitionOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #fff;
  z-index: 1000000000000;
  will-change: height;
}

.outTransitionOverlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: #fff;
  z-index: 1000000000000;
  will-change: height;
}

.homeContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
}

.categoryInnerContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  will-change: transform;
}

.headerContainer {
  width: calc(100% - 10vw);
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20vh;
  margin-bottom: 5vh;
}

.headerMain {
  height: 45vh;
  width: auto;
  object-fit: cover;
}

.headerSub {
  font-family: 'Helvetica Neue';
  font-weight: 200;
  font-size: 1.1vw;
  line-height: 2.2vw;
  text-align: right;
  width: 20vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
}

.headerImg {
  width: calc(100% - 10vw);
  height: auto;
  object-fit: cover;
  margin-bottom: 30vh;
}

.carouselContainer {
  width: calc(100% - 10vw);
  height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30vh;
}

.carouselTextContainer {
  width: 55%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.carouselTitleContainer {
  margin-top: 10vh;
  width: 100%;
  height: 7vw;
  position: relative;
  overflow: hidden;
  margin-bottom: 2vh;
}

.carouselTitle {
  position: absolute;
  top: 0;
  left: 0;
  font-family: 'DIN';
  font-weight: 700;
  font-size: 5vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 2vh;
  will-change: transform;
}

.carouselTextInnerContainer {
  position: relative;
  width: 100%;
  will-change: opacity;
}

.carouselText {
  position: absolute;
  top: 0;
  left: 0;
  font-family: 'Helvetica Neue';
  font-weight: 200;
  font-size: 1.1vw;
  line-height: 2.3vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
  width: 90%;
}

.carouselTextClick {
  display: none;
}

.carouselControlBtnsContainer {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.carouselControlBtn {
  width: 4vw;
  height: 4vw;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(43, 87, 124, 0.5);
  margin-left: 0.7vw;
  cursor: none;
  transition: transform 0.3s ease-out;
}

.carouselControlBtn:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.carouselControlIcon {
  width: 1.3vw;
  height: 1.3vw;
  color: #2B577C;
}

.carouselImgContainer {
  width: 35vw;
  height: 90vh;
  overflow: hidden;
  position: relative;
}

.carouselImg {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  width: 35vw;
  height: 90vh;
  object-fit: cover;
  will-change: opacity, transform;
}

.aboutContainer {
  width: calc(100% - 10vw);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30vh;
}

.aboutImgContainer {
  width: 100%;
  height: 50vh;
}

.aboutImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aboutTextContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15vh;
}

.aboutText {
  width: 50%;
  height: auto;
  font-family: 'Helvetica Neue';
  font-weight: 200;
  font-size: 1vw;
  line-height: 2vw;
  color: #2B577C;
  text-align: justify;
}

.aboutTitleContainer {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.aboutSub {
  font-family: 'Helvetica Neue';
  font-weight: 200;
  font-size: 1.5vw;
  color: #2B577C;
  margin-bottom: 2vh;
  margin-top: 0;
}

.aboutTitle {
  font-family: 'DIN';
  font-weight: 700;
  font-size: 9.5vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
}

.contactSkyline {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.contactContainer {
  z-index: 100;
  margin-top: -1vw;
  width: calc(100% - 10vw);
  padding-left: 5vw;
  padding-right: 5vw;
  height: auto;
  background-color: #2B577C;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.contactTextContainer {
  width: 35%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.contactTitle {
  font-family: 'DIN';
  font-weight: 700;
  font-size: 4.5vw;
  color: #fafafa;
  margin-top: 0;
  margin-bottom: 1vh;
}

.contactSubtitle {
  font-family: 'Helvetica Neue';
  font-weight: 200;
  font-size: 1vw;
  line-height: 2.4vw;
  color: #fafafa;
  margin-top: 0;
  margin-bottom: 0;
}

.contactFormContainer {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 15vh;
}

.contactFormButton {
  width: 4vw;
  height: 4vw;
  border-radius: 2vw;
  background-color: #fafafa;
  margin-top: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: none;
  transition: transform 0.3s ease-out;
}

.contactFormButton:hover {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
}

.contactFormButtonIcon {
  width: 1.3vw;
  height: 1.3vw;
  color: #2B577C;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .headerContainer {
    margin-top: 10vh;
    margin-bottom: 5vh;
  }
  
  .headerMain {
    height: auto;
    width: 50vw;
    object-fit: cover;
  }

  .headerImg {
    margin-bottom: 15vh;
  }
  
  .headerSub {
    font-size: 1.9vw;
    line-height: 3.8vw;
    text-align: right;
    width: 40vw;
  }
  
  .carouselContainer {
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30vh;
    position: relative;
  }
  
  .carouselTextContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
  }
  
  .carouselTitleContainer {
    margin-top: 5vh;
    width: 100%;
    height: 10vw;
    margin-bottom: 1vh;
  }
  
  .carouselTitle {
    font-size: 7vw;
    margin-bottom: 0;
  }
  
  .carouselTextInnerContainer {
    position: relative;
    width: 90%;
  }
  
  .carouselText {
    font-size: 1.8vw;
    line-height: 4vw;
    width: 85%;
    position: absolute;
  }

  .carouselTextClick {
    display: flex;
    font-weight: 700;
    text-decoration: underline;
  }
  
  .carouselControlBtnsContainer {
    position: absolute;
    right: 0;
    bottom: auto;
    top: 5vh;
  }
  
  .carouselControlBtn {
    width: 5vw;
    height: 5vw;
    border-radius: 2.5vw;
  }
  
  .carouselControlBtn:hover {
    opacity: 1;
  }
  
  .carouselControlIcon {
    width: 1.5vw;
    height: 1.5vw;
    color: #2B577C;
  }
  
  .carouselImgContainer {
    width: 100%;
    height: 25vh;
    overflow: hidden;
    position: relative;
  }

  .carouselImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .aboutContainer {
    margin-bottom: 15vh;
  }
  
  .aboutImgContainer {
    width: 100%;
    height: 25vh;
  }
  
  .aboutImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .aboutTextContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 5vh;
  }
  
  .aboutText {
    width: 100%;
    font-size: 1.8vw;
    line-height: 4vw;
  }
  
  .aboutTitleContainer {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 3vh;
  }
  
  .aboutSub {
    font-size: 2vw;
  }
  
  .aboutTitle {
    font-size: 10vw;
  }
  
  .contactSkyline {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .contactTextContainer {
    width: 40%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .contactTitle {
    font-size: 6vw;
  }
  
  .contactSubtitle {
    font-size: 1.8vw;
    line-height: 4vw;
  }
  
  .contactFormContainer {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 10vh;
  }
  
  .contactFormButton {
    width: 6vw;
    height: 6vw;
    border-radius: 3vw;
    background-color: #fafafa;
    margin-top: 2vh;
  }
  
  .contactFormButton:hover {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
  }
  
  .contactFormButtonIcon {
    width: 1.6vw;
    height: 1.6vw;
    color: #2B577C;
  }
  
  .homeFooterButton {
    width: 5vw;
    height: 5vw;
    border-radius: 2.5vw;
    margin-right: 2vw;
  }
  
  .homeFooterButton:hover {
    opacity: 1;
  }
  
  .homeFooterButtonIcon {
    width: 1.4vw;
    height: 1.4vw;
    color: #d1d1d1;
  }
  
  .homeFooterText {
    font-size: 1.6vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .headerContainer {
    margin-top: 10vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .headerMain {
    height: auto;
    width: 70vw;
    object-fit: cover;
    margin-bottom: 3vh;
  }

  .headerImg {
    margin-bottom: 20vh;
    height: 30vh;
  }
  
  .headerSub {
    font-size: 3.5vw;
    line-height: 7vw;
    text-align: right;
    width: 100%;
  }
  
  .carouselContainer {
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60vh;
    position: relative;
  }
  
  .carouselTextContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
  }
  
  .carouselTitleContainer {
    margin-top: 10vh;
    width: 100%;
    height: 13vw;
    margin-bottom: 1vh;
  }
  
  .carouselTitle {
    font-size: 10vw;
    margin-bottom: 0;
  }
  
  .carouselTextInnerContainer {
    position: relative;
    width: 100%;
  }
  
  .carouselText {
    font-size: 3.5vw;
    line-height: 8vw;
    width: 100%;
    position: absolute;
  }
  
  .carouselControlBtnsContainer {
    position: absolute;
    right: 0;
    bottom: auto;
    top: 2vh;
  }
  
  .carouselControlBtn {
    width: 10vw;
    height: 10vw;
    border-radius: 5vw;
    margin-left: 2vw;
  }
  
  .carouselControlBtn:hover {
    opacity: 1;
  }
  
  .carouselControlIcon {
    width: 4.5vw;
    height: 4.5vw;
    color: #2B577C;
  }
  
  .carouselImgContainer {
    width: 100%;
    height: 25vh;
    overflow: hidden;
    position: relative;
  }

  .carouselImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .aboutContainer {
    margin-bottom: 15vh;
  }
  
  .aboutImgContainer {
    width: 100%;
    height: 35vh;
  }
  
  .aboutImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .aboutTextContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 5vh;
  }
  
  .aboutText {
    width: 100%;
    font-size: 3.5vw;
    line-height: 8vw;
  }
  
  .aboutTitleContainer {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 3vh;
  }
  
  .aboutSub {
    font-size: 4vw;
  }
  
  .aboutTitle {
    font-size: 14vw;
  }
  
  .contactSkyline {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .contactContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .contactTextContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 5vh;
    margin-top: 5vh;
  }
  
  .contactTitle {
    font-size: 9vw;
  }
  
  .contactSubtitle {
    font-size: 3.5vw;
    line-height: 8vw;
  }
  
  .contactFormContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 10vh;
  }
  
  .contactFormButton {
    width: 12vw;
    height: 12vw;
    border-radius: 6vw;
    background-color: #fafafa;
    margin-top: 2vh;
  }
  
  .contactFormButton:hover {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
  }
  
  .contactFormButtonIcon {
    width: 4vw;
    height: 4vw;
    color: #2B577C;
  }
  
  .homeFooterButton {
    width: 10vw;
    height: 10vw;
    border-radius: 5vw;
    margin-right: 3vw;
  }
  
  .homeFooterButton:hover {
    opacity: 1;
  }
  
  .homeFooterButtonIcon {
    width: 3vw;
    height: 3vw;
    color: #d1d1d1;
  }
  
  .homeFooterText {
    font-size: 3vw;
    text-align: right;
  }
}