.gestionSubheader {
  width: 100%;
  margin-top: 15vh;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: hidden;
}

.gestionSubheaderAuxText {
  font-family: 'Helvetica Neue';
  font-weight: 200;
  font-size: 1vw;
  line-height: 2vw;
  color: #2B577C;
  margin-top: 1.5vh;
  margin-bottom: 0;
  width: 20vw;
  text-align: left;
  margin-left: 2vw;
}

.gestionSubheaderText {
  font-family: 'DIN';
  font-weight: 700;
  font-size: 12vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -1vw;
}

.gestionHeader {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -12vh;
}

.gestionHeaderText {
  font-family: 'DIN';
  font-weight: 700;
  font-size: 19vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -2vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .gestionSubheader {
    width: 100%;
    margin-top: 12vh;
  }

  .gestionSubheaderText {
    font-family: 'DIN';
    font-weight: 700;
    font-size: 15vw;
    color: #2B577C;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -1vw;
  }
  
  .gestionSubheaderAuxText {
    font-size: 1.8vw;
    line-height: 3.1vw;
    margin-top: 0.5vh;
    width: 45vw;
  }
  
  .gestionHeader {
    margin-top: -4vh;
  }
  
  .gestionHeaderText {
    margin-left: -2.5vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .gestionSubheader {
    width: 100%;
    margin-top: 12vh;
  }

  .gestionSubheaderText {
    font-size: 18vw;
    margin-left: -2vw;
  }
  
  .gestionSubheaderAuxText {
    display: none;
  }
  
  .gestionHeader {
    margin-top: -3.5vh;
  }
  
  .gestionHeaderText {
    margin-left: -2vw;
  }
}