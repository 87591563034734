.contactBannerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20vh;
  margin-bottom: 15vh;
  align-self: flex-end;
  margin-right: 5vw;
}

.contactBannerTextContainer {
  width: auto;
  height: auto;
  margin-right: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.contactBannerTitle {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 4vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
}

.contactBannerText {
  font-family: 'Helvetica Neue';
  font-weight: 200;
  font-size: 1.1vw;
  line-height: 2.5vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
  width: 35vw;
  text-align: right;
}

.contactBannerButton {
  width: 8vw;
  height: 8vw;
  border-radius: 4vw;
  background-color: #2B577C;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: none;
  transition: transform 0.3s ease-out;
}

.contactBannerButtonIcon {
  width: 1.5vw;
  height: 1.5vw;
  color: #fafafa;
}

.contactBannerButton:hover {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
}

@media (orientation: portrait) and (max-width: 1024px) {
  .contactBannerContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10vh;
    margin-bottom: 8vh;
    align-self: flex-end;
    margin-right: 5vw;
  }

  .contactBannerTextContainer {
    margin-right: 3vw;
  }
  
  .contactBannerTitle {
    font-size: 5vw;
    margin-bottom: 0.5vh;
  }
  
  .contactBannerText {
    font-size: 1.8vw;
    line-height: 3vw;
    width: 80vw;
  }
  
  .contactBannerButton {
    width: 12vw;
    height: 12vw;
    border-radius: 6vw;
  }
  
  .contactBannerButtonIcon {
    width: 2.5vw;
    height: 2.5vw;
    color: #fafafa;
  }
  
  .contactBannerButton:hover {
    transform: none;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .contactBannerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 10vw);
    margin-top: 10vh;
    margin-bottom: 8vh;
    align-self: flex-end;
    margin-right: 5vw;
  }

  .contactBannerTextContainer {
    justify-content: center;
    align-items: flex-start;
    margin-right: 3vw;
  }
  
  .contactBannerTitle {
    font-size: 9vw;
    margin-bottom: 0.5vh;
  }
  
  .contactBannerText {
    font-size: 3.5vw;
    line-height: 7vw;
    width: 70vw;
    text-align: left;
  }
  
  .contactBannerButton {
    width: 15vw;
    height: 15vw;
    border-radius: 7.5vw;
  }
  
  .contactBannerButtonIcon {
    width: 2.5vw;
    height: 2.5vw;
    color: #fafafa;
  }
  
  .contactBannerButton:hover {
    transform: none;
  }
}