.menuContainer {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
}

.menuClose {
  position: absolute;
  top: 3vh;
  right: 5vw;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(43, 87, 124, 0.7);
}

.menuCloseIcon {
  width: 15px;
  height: 15px;
  color: #2B577C;
}

.menuTopLine {
  width: 0;
  height: 3vh;
  border-left: 1px solid rgba(43, 87, 124, 0.7);
  position: fixed;
  top: 0;
  left: 50%;
}

.menuBottom {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.menuBottomText {
  font-family: 'DIN';
  font-weight: 700;
  color: #2B577C;
  font-size: 2.3vw;
  margin-top: 0;
  margin-bottom: 0;
  position: fixed;
  left: 50%;
  bottom: 4vh;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  width: 50vw;
  text-align: center;
}

.menuBottomLine {
  width: 0;
  height: 3vh;
  border-left: 1px solid rgba(43, 87, 124, 0.7);
}

.menuInner {
  width: 90vw;
  height: auto;
  margin-top: 19vh;
  padding-left: 5vw;
  padding-right: 5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.menuButtonImg {
  border: 1px solid #d1d1d1;
  width: 18%;
  height: 50vh;
  object-fit: cover;
  will-change: opacity;
}

.menuContactLink {
  font-family: 'DIN';
  font-weight: 700;
  color: #2B577C;
  font-size: 2.3vw;
  margin-top: 0;
  margin-bottom: 0;
  position: fixed;
  right: 0;
  bottom: 4vh;
  width: auto;
  padding-right: 5vw;
  text-align: left;
  border-bottom: 1px solid rgba(43, 87, 124, 0.7);
}

@media (orientation: portrait) and (max-width: 1024px) {
  .menuClose {
    height: 40px;
    width: 40px;
  }

  .mobileMenuInner {
    width: 95vw;
    height: auto;
    margin-top: 13vh;
    padding-left: 0;
    padding-right: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .mobileMenuSubtitle {
    font-family: 'DIN';
    font-weight: 700;
    color: #2B577C;
    font-size: 7vw;
    margin-top: 0;
    margin-bottom: 4.5vh;
    padding-left: 5vw;
    border-bottom: 1px solid #2B577C;
  }

  .mobileMenuLink {
    font-family: 'Helvetica Neue';
    font-weight: 200;
    color: #2B577C;
    font-size: 3.5vw;
    margin-top: 0;
    margin-bottom: 4vh;
    margin-left: 0;
    width: 100%;
    text-align: right;
  }

  .bottomMenuSubtitle {
    margin-top: 2vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .menuClose {
    height: 45px;
    width: 45px;
  }

  .mobileMenuInner {
    width: 95vw;
    height: auto;
    margin-top: 12vh;
    padding-left: 0;
    padding-right: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .mobileMenuSubtitle {
    font-family: 'DIN';
    font-weight: 700;
    color: #2B577C;
    font-size: 10vw;
    margin-top: 0;
    margin-bottom: 4vh;
  }

  .mobileMenuLink {
    font-family: 'Helvetica Neue';
    font-weight: 200;
    color: #2B577C;
    font-size: 5vw;
    margin-top: 0;
    margin-bottom: 5vh;
    margin-left: 0;
    width: 100%;
    text-align: right;
  }

  .bottomMenuSubtitle {
    margin-top: 3vh;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {
  
  .menuClose {
    position: absolute;
    top: 3vh;
    right: 5vw;
    height: 35px;
    width: 35px;
  }
  
  .menuCloseIcon {
    width: 12px;
    height: 12px;
    color: #2B577C;
  }
  
  .menuBottom {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  
  .menuBottomText {
    display: none;
  }
  
  .menuBottomLine {
    width: 0;
    height: 3vh;
    border-left: 1px solid rgba(43, 87, 124, 0.7);
  }
  
  .menuInner {
    width: 70vw;
    height: auto;
    margin-top: 15vh;
    padding-left: 15vw;
    padding-right: 15vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .menuButtonImg {
    border: 1px solid #aaa;
    width: 18%;
    height: 35vh;
    object-fit: cover;
  }
}