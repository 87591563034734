.navbarContainer {
  margin-top: 3vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 9vh;
  width: auto;
  object-fit: cover;
  cursor: none;
  transition: transform 0.3s ease-out;
}

.logo:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

.navbarMenuButton {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(43, 87, 124, 0.7);
  margin-right: 5vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .navbarContainer {
    margin-top: 3vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    height: 4vh;
  }

  .navbarMenuButton {
    height: 40px;
    width: 40px;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .navbarContainer {
    margin-top: 3vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    height: 8vh;
    margin-left: -6vw;
  }

  .navbarMenuButton {
    height: 45px;
    width: 45px;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {
  .navbarMenuButton {
    height: 35px;
    width: 35px;
    border-radius: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(43, 87, 124, 0.7);
    margin-right: 5vw;
  }
}