.graficasSubheader {
  width: 100%;
  margin-top: 20vh;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  overflow-x: hidden;
}

.graficasSubheaderAuxText {
  font-family: 'Helvetica Neue';
  font-weight: 200;
  font-size: 1vw;
  line-height: 2vw;
  color: #2B577C;
  margin-top: 1.5vh;
  margin-bottom: 0;
  width: 20vw;
  text-align: right;
  margin-right: 2vw;
}

.graficasSubheaderText {
  font-family: 'DIN';
  font-weight: 700;
  font-size: 12vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: -0.5vw;
}

.graficasHeader {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -8vh;
}

.graficasHeaderText {
  font-family: 'DIN';
  font-weight: 700;
  font-size: 23vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -1vw;
}

.graficasHeaderImg {
  width: calc(100% - 10vw);
  height: auto;
  object-fit: cover;
  margin-top: 5vh;
  margin-bottom: 10vh;
}

.graficasAuxText {
  font-family: 'Helvetica Neue';
  font-weight: 200;
  font-size: 1.3vw;
  line-height: 4vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 10vh;
  width: 55vw;
  text-align: left;
  margin-left: 5vw;
  align-self: flex-start;
}

.stepContainer {
  width: calc(100% - 10vw);
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 18vh;
  margin-bottom: 18vh;
}

.stepNumber {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 20vw;
  line-height: 13vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 5vw;
}

.stepInfoContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 4vh;
  border-top: 5px solid #2B577C;
}

.stepTitle {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 3.5vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
}

.stepText {
  font-family: 'Helvetica Neue';
  font-weight: 200;
  font-size: 1.1vw;
  line-height: 3vw;
  color: #2B577C;
  margin-top: 0;
  margin-bottom: 0;
  text-align: right;
  width: 50%;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .graficasSubheader {
    width: 100%;
    margin-top: 12vh;
  }
  
  .graficasSubheaderAuxText {
    font-size: 1.5vw;
    line-height: 3vw;
    margin-top: 0.5vh;
    width: 40vw;
  }
  
  .graficasHeader {
    margin-top: -2.5vh;
  }
  
  .graficasHeaderText {
    margin-left: -3vw;
  }
  
  .graficasHeaderImg {
    margin-bottom: 5vh;
  }
  
  .graficasAuxText {
    font-size: 2.4vw;
    line-height: 7vw;
    margin-bottom: 5vh;
    width: 75vw;
  }
  
  .stepContainer {
    margin-top: 8vh;
    margin-bottom: 8vh;
  }
  
  .stepNumber {
    font-size: 20vw;
    line-height: 13vw;
    margin-right: 5vw;
  }
  
  .stepInfoContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 2vh;
    border-top: 5px solid #2B577C;
  }
  
  .stepTitle {
    font-size: 4vw;
  }
  
  .stepText {
    font-size: 1.8vw;
    line-height: 5vw;
    width: 60%;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .graficasSubheader {
    width: 100%;
    margin-top: 12vh;
  }
  
  .graficasSubheaderAuxText {
    display: none;
  }
  
  .graficasHeader {
    margin-top: -1.5vh;
  }
  
  .graficasHeaderText {
    margin-left: -3vw;
  }
  
  .graficasHeaderImg {
    width: calc(100% - 10vw);
    height: 30vh;
    margin-bottom: 5vh;
    margin-top: 2vh;
  }
  
  .graficasAuxText {
    font-size: 3.8vw;
    line-height: 10vw;
    margin-bottom: 5vh;
    width: 90vw;
  }
  
  .stepContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 8vh;
    margin-bottom: 8vh;
  }
  
  .stepNumber {
    font-size: 30vw;
    line-height: 22vw;
    margin-right: 5vw;
  }
  
  .stepInfoContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 2vh;
    border-top: 5px solid #2B577C;
  }
  
  .stepTitle {
    font-size: 8vw;
  }
  
  .stepText {
    font-size: 3.8vw;
    line-height: 8vw;
    width: 85%;
    align-self: flex-end;
    margin-top: 1.5vh;
  }
}